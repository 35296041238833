import { FC } from "react";
import { IconNames, IconProps } from "./Icon";
import { ReactComponent as UsersIcon } from "../../../assets/icons/Ресурс 1.svg";
import { ReactComponent as DropdownIcon } from "../../../assets/icons/Ресурс 251.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/Ресурс 21.svg";
import { ReactComponent as ExpandIcon } from "../../../assets/icons/Ресурс 259.svg";
import { ReactComponent as SunIcon } from "../../../assets/icons/Ресурс 4.svg";
import { ReactComponent as MenuIcon } from "../../../assets/icons/sketchico/essentials/basics/menu.svg";
import { ReactComponent as ResultsIcon } from "../../../assets/icons/results.svg";

import clsx from "clsx";

const SketchIcon: FC<IconProps> = ({ name, className, style }) => {
  const iconStyle = clsx("fill-white h-[24px]", className);

  switch (name) {
    case IconNames.USERS:
      return <UsersIcon className={iconStyle} style={style}/>;
    case IconNames.DROPDOWN:
      return <DropdownIcon className={iconStyle} style={style} />;
    case IconNames.DOWNLOAD:
      return <DownloadIcon className={iconStyle} style={style} />;
    case IconNames.EXPAND:
      return <ExpandIcon className={iconStyle} style={style} />;
    case IconNames.SUN:
      return <SunIcon className={iconStyle} style={style} />;
    case IconNames.MENU:
      return <MenuIcon className={iconStyle} style={style} />;
    case IconNames.RESULTS:
      return <ResultsIcon className={iconStyle} style={style} />;
    default:
      return null;
  }
};

export default SketchIcon;
