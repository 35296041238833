import React, { FC, useState } from "react";
import Text, { TextSizes } from "../ui/typography/Text";
import Icon, { IconNames, IconSize } from "../ui/icons/Icon";
import SidebarDropdownOption, {
  SidebarDropdownOptionProps,
} from "./SidebarDropdownOption";
import { Transition } from "react-transition-group";

interface SidebarDropdownProps {
  label: string;
  options: SidebarDropdownOptionProps[];
}

const SidebarDropdown: FC<SidebarDropdownProps> = ({ options, label }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      className="
          flex
          flex-col
          w-full
          gap-[20px]
          text-white
        "
    >
      <div
        className="
          flex
          items-center
          cursor-pointer
          justify-between
          font-bold
        "
        onClick={() => setExpanded((prev) => !prev)}
      >
        <Text>{label}</Text>
        <Icon
          name={IconNames.DROPDOWN}
          className={`
            ${IconSize.SMALL}
            fill-white
            transition
            ${expanded ? "rotate-0" : "rotate-180"}
          `}
        />
      </div>
      <Transition in={expanded} timeout={200} unmountOnExit>
        {(state) => (
          <div
            className={`
              flex
              flex-col
              w-full
              gap-[20px]
              transition-all 
              ${state === "entered" ? "opacity-100" : "opacity-0"}
              ${state === "entered" ? "h-full" : "h-0"}
            `}
          >
            {options.map((option) => (
              <SidebarDropdownOption key={option.id} {...option} />
            ))}
          </div>
        )}
      </Transition>
    </div>
  );
};

export default SidebarDropdown;
