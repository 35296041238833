import React, {
  ButtonHTMLAttributes,
  FC,
  PropsWithChildren,
  useMemo,
} from "react";
import Icon, { IconNames, IconSize } from "../icons/Icon";
import clsx from "clsx";
import ActivityIndicator, {
  IndicatorSizes,
} from "../indicators/ActivityIndicator";
import tw, { styled } from "twin.macro";

export enum ButtonStyles {
  PURPLE_PRIMARY = "bg-purple-primary text-white hover:bg-purple-secondary",
  PURPLE_SECONDARY = "bg-purple-200 text-purple-primary hover:bg-purple-secondary",
  LIGHT = "bg-light-primary text-purple-primary hover:bg-light-secondary",
  ERROR_SECONDARY = "bg-blue-primary text-white hover:bg-blue-secondary",
}

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: ButtonStyles;
  icon?: IconNames;
  iconStyle?: string;
  loading?: boolean;
  size?: "smallest" | "small" | "medium" | "large"
}

const Button: FC<PropsWithChildren<ButtonProps>> = ({
  color = ButtonStyles.PURPLE_PRIMARY,
  icon,
  iconStyle,
  type,
  size,
  onClick,
  loading,
  children,
  ...props
}) => {
  const iconColor = useMemo(() => {
    switch (color) {
      case ButtonStyles.ERROR_SECONDARY:
        return "fill-white";
      case ButtonStyles.PURPLE_PRIMARY:
        return "fill-white";
      default:
        return "fill-purple";
    }
  }, [color]);

  const iconSize = useMemo(() => {
    switch (size) {
    case "smallest":
        return IconSize.MEDIUM
      case "small":
        return IconSize.MEDIUM
      case "medium":
        return IconSize.LARGE
      case "large":
        return IconSize.XL
      default:
        return IconSize.LARGE
    }
  }, [size])

  return (
    <StyledButton
      type={type}
      onClick={onClick}
      {...props}
      className={`${props.className} ${color}`}
    >
      {loading && (
        <ActivityIndicator
          color={(color === ButtonStyles.PURPLE_PRIMARY || color === ButtonStyles.ERROR_SECONDARY) ? "text-white" : 'text-purple-primary'}
          size={IndicatorSizes.MEDIUM}
        />
      )}
      {icon && (
        <Icon
          name={icon}
          className={clsx(iconColor, iconSize)}
        />
      )}
      <StyledButtonText size={size}>{children}</StyledButtonText>
    </StyledButton>
  );
};

const StyledButton = styled('button')<ButtonProps>(({color, className}) =>[
  tw`
    w-full flex flex-row items-center justify-between
    cursor-pointer transition rounded-full
    disabled:pointer-events-none disabled:brightness-75
    p-[10px] max-w-[500px]
  `,
])

const StyledButtonText = styled('div')<{size?: "smallest" | "small" | "medium" | "large"}>(
  ({size = "medium"}) => {
    let textStyle = tw`font-semibold text-lg uppercase`

    switch(size) {
      case 'smallest':
        textStyle = tw`font-medium text-[18px] md:text-[18px]`
        break;
      case 'small':
        textStyle = tw`font-medium text-[20px] md:text-[24px]`
        break;
      case 'medium':
        textStyle = tw`font-semibold text-md sm:text-lg uppercase`
        break;
      case 'large':
        textStyle = tw`font-semibold text-[20px] md:text-[24px] uppercase`
        break;
    }

    return [
      tw`flex-1 px-sm`,
      textStyle,
    ]
  }
)

export default Button;
