import { FC } from "react";
import WidePage from '../../components/WidePage';
import { theme } from 'twin.macro';
import { ReactComponent as FormImg } from "../../assets/hands/general/workflow, hand gestures _ hand, gesture, data, confirm, document, checkmark, laptop.svg";

import H2 from "../../components/ui/typography/H2";
import H3 from '../../components/ui/typography/H3'

const Page: FC<{}> = () => {
  return (
    <WidePage
      img={<FormImg className="w-full h-full"/>}
      imgBgColor={theme`colors.blue.primary`}
      shadowColor={theme`colors.dark.200`}
      header={(
        <div>
          <H2>Функциональные характеристики ТРОПАСС</H2>
        </div>
      )}
    >
      <div
        className="pt-sm sm:pt-md xl:pt-lg text-dark-300 text-base font-medium md:text-[18px] card-text"
        style={{fontFamily: "Inter"}}
      >
        <p>Симулятор будущего ТРОПАСС является цифровой платформой, реализованной в виде клиент-серверного веб приложения, размещенного по адресу: <a href="/">тропасс.рф</a>.</p>
        <p>ТРОПАСС предоставляет пользователю возможности разработки персональных прогнозов на основе различных моделей искусственного интеллекта, представленных в виде сервисов платформы.</p>
        <p>Для разработчиков ТРОПАСС предоставляет функционал быстрого размещения собственных сервисов на основе искусственного интеллекта без необходимости разработки клиентской части (frontend), серверной части (backend), сборки, настройки и развертывания ПО (devops).</p>
        <H3>Что может ТРОПАСС?</H3>
        <ol>
          <li>
            Разработка персональных пользовательских прогнозов в различных сферах жизни и хозяйствования с описаниями полученных результатов симуляции и рекомендациями.
          </li>
          <li>
            Развертывание в готовой веб-среде моделей искусственного интеллекта с поддержкой мультиязычности в 1 клик без необходимости разработки клиентской части (frontend), серверной части (backend), сборки, настройки и развертывания ПО (devops).
          </li>
          <li>
            Магазин сервисов на основе искусственного интеллекта с интегрированной средой их дальнейшего использования на цифровой платформе ТРОПАСС (в разработке).
          </li>
          <li>
            Проведение симуляция различных сценариев для построения прогнозов (прокладка троп) на основе одного из сервисов искусственного интеллекта. Пользователь может строить неограниченное число симуляций, все они будут выводиться в пользовательский интерфейс с возможностью визуального сравнения.
          </li>
          <li>
            Агрегатор ожиданий пользователей (физических и юридических лиц) о будущей жизни в Российской Федерации.
          </li>
        </ol>

        
        <H3>Стоимость использования ТРОПАСС</H3>
        
        <p>
          В настоящее время сервисы ТРОПАСС предоставляются пользователям на безвозмездной основе.
        </p>
        <p>
          Плата за регистрацию на платформе не взимается.
        </p>

        
        <H3>Информация по установке ТРОПАСС</H3>
        
        <p>
          ТРОПАСС является веб-серверным приложением и не требует дополнительной установки на компьютер пользователя. Цифровая платформа доступна в режиме 24/7 по адресу: <a href="/">тропасс.рф</a>
        </p>
        
        <H3>Информация по эксплуатации ТРОПАСС</H3>
        
        <p>
          В настоящее время в ТРОПАСС доступно 4 сервиса: 
        </p>
        <ul>
          <li>Семейные ценности;</li>
          <li>Квартирный вопрос;</li>
          <li>Карьерное развитие;</li>
          <li>Пенсии.</li>
        </ul>
        <p>
          Сервис позволяет получать персональные ответы пользователю о том, как выглядит его жизнь на общем фоне с точки зрения продолжительности жизни и иных демографических показателей в конкретном регионе.
        </p>
        <p>
          Сервис, позволяющий оценить стоимость недвижимости. Он может проанализировать и отобразить прошлые тенденции и перспективы её стоимости в Вашем регионе. Сервис предложит различные сценарии и оценит Ваш регион с точки зрения рынка недвижимости.
        </p>
        <p>
          Сервис, который поможет не только реально оценить Ваши перспективы на рабочем месте, но и предложит выгодные альтернативы, в случае если текущая специальность Вам более не мила. Сервис предоставляет информацию специальности: среднюю зарплату и ее изменение, изменение спроса на профессию, состояние дел в соседних регионах. 
        </p>
        <p>
          Сервис, позволяющий оценить уровень накоплений в рамках пенсионной программы и сформировать последовательный и реальный путь к достижению в запланированные Вами сроки запланированного уровня ежемесячного дохода, который позволит обеспечить достойный уровень жизни.
        </p>
        <p>
          Подробнее о работе с ТРОПАСС можно узнать из видео.
        </p>
        <br/>
        <video
          width="90%"
          height="auto"
          controls={true}
          autoPlay={true}
          loop={true}
          className="mx-auto"
          poster="/assets/screenshot.png"
        >
          <source src="/assets/video/Тропасс2.mp4" type="video/mp4"/>
          <source src="/assets/video/Тропасс2.mov" type="video/quicktime"/>
          <source src="/assets/video/Тропасс2.ogx" type="video/ogg"/>
          <source src="/assets/video/Тропасс2.webm" type="video/webm"/>
        </video>
        <br/>
      </div>
    </WidePage>
  )
}

export default Page