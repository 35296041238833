import { FC } from "react";
import { IconNames, IconProps } from "./Icon";
import { ReactComponent as UsersIcon } from "../../../assets/icons/Ресурс 1.svg";
import { ReactComponent as DropdownIcon } from "../../../assets/icons/Ресурс 251.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/Ресурс 21.svg";
import { ReactComponent as ExpandIcon } from "../../../assets/icons/Ресурс 259.svg";
import { ReactComponent as CollapseIcon } from "../../../assets/icons/Ресурс 261.svg";
import { ReactComponent as SunIcon } from "../../../assets/icons/Ресурс 4.svg";
import { ReactComponent as MenuIcon } from "../../../assets/icons/Ресурс 4.svg";
import { ReactComponent as ExitIcon } from "../../../assets/icons/Ресурс 119.svg";
import { ReactComponent as ChartPieIcon } from "../../../assets/icons/Ресурс 36.svg";
import { ReactComponent as CompassIcon } from "../../../assets/icons/Ресурс 132.svg";
import { ReactComponent as CopyFilesIcon } from "../../../assets/icons/Ресурс 293.svg";
import { ReactComponent as FileEditIcon } from "../../../assets/icons/Ресурс 280.svg";
import { ReactComponent as FileDeleteIcon } from "../../../assets/icons/Ресурс 32.svg";
import { ReactComponent as RefreshIcon } from "../../../assets/icons/Ресурс 75.svg";
import { ReactComponent as ResultsIcon } from "../../../assets/icons/results.svg";

import clsx from "clsx";

const FutureIcon: FC<IconProps> = ({ name, className, style }) => {
  const iconStyle = clsx("", className);

  switch (name) {
    case IconNames.USERS:
      return <UsersIcon className={iconStyle} style={style} />;
    case IconNames.DROPDOWN:
      return <DropdownIcon className={iconStyle} style={style} />;
    case IconNames.DOWNLOAD:
      return <DownloadIcon className={iconStyle} style={style} />;
    case IconNames.EXPAND:
      return <ExpandIcon className={iconStyle} style={style} />;
    case IconNames.COLLAPSE:
      return <CollapseIcon className={iconStyle} style={style} />;
    case IconNames.SUN:
      return <SunIcon className={iconStyle} style={style} />;
    case IconNames.MENU:
      return <MenuIcon className={iconStyle} style={style} />;
    case IconNames.EXIT:
      return <ExitIcon className={iconStyle} style={style} />;
    case IconNames.CHART_PIE:
      return <ChartPieIcon className={iconStyle} style={style} />;
    case IconNames.COMPASS:
      return <CompassIcon className={iconStyle} style={style} />;
    case IconNames.FILE_COPY:
      return <CopyFilesIcon className={iconStyle} style={style} />;
    case IconNames.FILE_EDIT:
      return <FileEditIcon className={iconStyle} style={style} />;
    case IconNames.FILE_DELETE:
      return <FileDeleteIcon className={iconStyle} style={style} />;
    case IconNames.REFRESH:
      return <RefreshIcon className={iconStyle} style={style} />;
    case IconNames.RESULTS:
      return <ResultsIcon className={iconStyle} style={style} />;
    default:
      return null;
  }
};

export default FutureIcon;
