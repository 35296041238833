import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getApi from "../../api";
import { AxiosError } from "axios";
import { IProfileResult } from "../../api/interfaces";
import { LoadableReduxData, LoadingStatus } from "../utilityTypes";
import { IApplicationAPI } from "../../api/interfaces/applications";
import { getMessageFromDefaultResponseError } from "../helpers";

interface IForecast extends LoadableReduxData {
  result: string;
}

interface IProfileResultSlice {
  status: LoadingStatus;
  error: string;
  profileResults: Array<IProfileResult.Response> | undefined;
  filterValue: { person: string[]; model: string[] };
  render: number;
  forecast: IForecast;
}

export const getProfileResults = createAsyncThunk(
  "profileResults/getProfileResults",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getApi.getProfileResults();
      return response.data;
    } catch (e: any) {
      const err = e as AxiosError<IProfileResult.Error>;
      return rejectWithValue(err.response?.data);
    }
  },
);

export const makeForecast = createAsyncThunk(
  "profileResults/makeForecast",
  async (personId: string, { rejectWithValue }) => {
    try {
      const response = await getApi.makeForecast(personId);
      return response.data;
    } catch (e) {
      const err = e as AxiosError<IApplicationAPI.makeForecast.Error>;
      return rejectWithValue(err.response?.data);
    }
  },
);

const initialState: IProfileResultSlice = {
  status: "idle",
  error: "",
  profileResults: undefined,
  filterValue: { person: [], model: [] },
  render: 0,
  forecast: {
    result: "",
    status: "idle",
    error: "",
  },
};

const profileResultSlice = createSlice({
  name: "profileResults",
  initialState,
  reducers: {
    filterByPerson(state, action) {
      if (state.profileResults) {
        if (action.payload === '') {
          state.filterValue.person = []
          return
        }
  
        const index = state.filterValue.person.indexOf(action.payload)

        if (index === -1) {
          state.filterValue.person.push(action.payload);
        } else {
          state.filterValue.person.splice(index, 1)
        }
      }
    },
    filterByModel(state, action) {
      if (state.profileResults) {
        if (action.payload === '') {
          state.filterValue.model = []
          return
        }

        const index = state.filterValue.model.indexOf(action.payload)

        if (index === -1) {
          state.filterValue.model.push(action.payload);
        } else {
          state.filterValue.model.splice(index, 1)
        }
      }
    },
    resetFilter(state) {
      state.filterValue = { person: [], model: [] };
    },
  },
  extraReducers: (build) => {
    build
      .addCase(getProfileResults.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.profileResults = action.payload;
      })
      .addCase(getProfileResults.pending, (state) => {
        state.status = "pending";
        state.error = "";
      })
      .addCase(getProfileResults.rejected, (state, action) => {
        state.status = "rejected";
      })
      .addCase(makeForecast.fulfilled, (state, action) => {
        state.forecast.status = "fulfilled";
        state.forecast.result = action.payload.message;
        state.render += 1;
      })
      .addCase(makeForecast.pending, (state) => {
        state.forecast.status = "pending";
        state.forecast.error = "";
      })
      .addCase(makeForecast.rejected, (state, action) => {
        const error = action.payload as IApplicationAPI.makeForecast.Error;
        state.forecast.status = "rejected";
        state.forecast.result = "";
        state.forecast.error = getMessageFromDefaultResponseError(error);
      });
  },
});

export const { filterByPerson, filterByModel, resetFilter } =
  profileResultSlice.actions;

export default profileResultSlice.reducer;
