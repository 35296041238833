import {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import Cookies from "js-cookie";
import { useReduxDispatch } from "../redux/store";
import { getMe } from "../redux/slices/authSlice";

interface IAuthProps {
  logged: boolean;
}

const initialState: IAuthProps = {
  logged: false,
};

export const AuthContext = createContext<IAuthProps>(initialState);

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useReduxDispatch();
  const [logged, setLogged] = useState<boolean>(!!Cookies.get("access"));

  useEffect(() => {
    const checkCookie = () => {
      const accessToken = Cookies.get("access");
      setLogged(!!accessToken);
    };

    const interval = setInterval(checkCookie, 500);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (logged) {
      dispatch(getMe());
    }
  }, [logged]);

  return (
    <AuthContext.Provider value={{ logged }}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
