import { FC, PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const GuestGuard: FC<PropsWithChildren> = ({ children }) => {
  const { logged } = useAuth();

  if (logged) {
    return <Navigate to="/home/dashboard" />;
  }

  return <>{children}</>;
};

export default GuestGuard;
