import { FC, PropsWithChildren, ReactNode } from "react";
import tw, { theme, styled, css } from 'twin.macro';

import { ReactComponent as Logo } from "../assets/ui/application_form_logo.svg";
import { useNavigate } from "react-router-dom";
import { version } from '../utils/config'

interface WidePageProps {
  className?: string
  header: ReactNode
  img: ReactNode
  imgBgColor: string
  shadowColor: string
}

const Version = styled.i(() => [
  tw`
    text-xs text-dark font-medium not-italic text-right
    relative inline-block w-full top-[-12px]
  `
])

const WidePage: FC<PropsWithChildren<WidePageProps>> = (
  { header, img, shadowColor, imgBgColor, children, className }
) => {
  const navigate = useNavigate();
  return (
    <>
    <StyledMobileLogo>
      <div onClick={() => navigate("/home/dashboard")} className="cursor-pointer w-[160px] md:w-[200px] pt-[9px]">
        <Logo className="w-full h-full"/>
        <Version>{version}</Version>
      </div>
      <div className="sm:hidden">
        {/*<Button>Back</Button>*/}
      </div>
    </StyledMobileLogo>

    <StyledHeaderWrapper>
      <StyledImg
        $bgColor={imgBgColor}
        $shadowColor={shadowColor}
      >
        {img}
      </StyledImg>
      <StyledHeader>
        <div></div>
        {header}
      </StyledHeader>
    </StyledHeaderWrapper>
    {children}
    </>
  )
}

const StyledHeaderWrapper = styled.div`
  ${tw`sm:(flex justify-between items-start flex-row gap-md)`}
`

const StyledMobileLogo = styled.div(() => [
  tw`
    w-full flex flex-row justify-between items-center py-xs pt-[10px]
    sm:(pt-[27px] w-[236px])
  `,
])

const StyledImg = styled.div<{$bgColor?: string, $shadowColor?: string}>(({$bgColor, $shadowColor}) => [
  tw`
    w-full max-w-[250px] rounded-lg p-sm mx-auto my-sm
    sm:(w-[230px] order-2 rounded-md p-sm rotate-[10deg] my-0)
    lg:(block w-1/3 mx-0 max-w-[520px] rounded-lg p-md)
  `,
  css`
    box-shadow: -10px -10px 0 0 ${$shadowColor};
    background-color: ${$bgColor};

    @media (min-width: ${theme`screens.sm`}) {
      box-shadow: -15px -15px 0 0 ${$shadowColor};
    }

    @media (min-width: ${theme`screens.lg`}) {
      box-shadow: -20px -20px 0 0 ${$shadowColor};
    }

    @media (min-width: ${theme`screens.xl`}) {
      box-shadow: -40px -40px 0 0 ${$shadowColor};
    }
  `,
])

const StyledHeader = styled.div(() => [
  tw`
    text-purple-primary
    sm:(self-stretch flex flex-col justify-between items-start order-1 flex-1)
  `
])

export default WidePage