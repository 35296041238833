import Router from "./router";
import { FC } from "react";
import AuthProvider from "./contexts/AuthContext";

const App: FC = () => {
  return (
    <AuthProvider>
      <Router />
    </AuthProvider>
  );
};

export default App;
