import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import ReactDOM from "react-dom";

import P1 from '../components/ui/typography/P1'

interface ModalProps {
  isOpen: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  onClose?: () => void;
  children: React.ReactNode;
  header: string
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  setOpen,
  onClose,
  children,
  header
}) => {
  const closeModal = () => {
    setOpen && setOpen(false);
    onClose && onClose();
  };

  // Создаем новый DOM-узел для модального окна
  const modalRoot = document.getElementById("modal-root");
  if (!modalRoot) {
    throw new Error('Element with id "modal-root" not found in the document.');
  }

  // Рендерим содержимое модального окна в созданный DOM-узел
  return ReactDOM.createPortal(
    isOpen ? (
      <div
        className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50 left-0"
        onClick={closeModal}
      >
        <div
          className="modal bg-white rounded-md p-4 flex flex-col"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex flex-row justify-between items-center pb-sm">
            <P1 className="text-purple">{header}</P1>
            <span className="close cursor-pointer text-[30px] leading-[1] text-purple-300" onClick={closeModal}>
              &times;
            </span>
          </div>
          {children}
        </div>
      </div>
    ) : null,
    modalRoot,
  );
};

export default Modal;
