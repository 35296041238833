import { ReactComponent as Logo } from "../assets/ui/logo.svg";
import { ReactComponent as MobileLogo } from "../assets/ui/application_form_logo.svg";
import { Dispatch, FC, SetStateAction } from "react";
import Icon, { IconFamily, IconNames } from "./ui/icons/Icon";
import { useLocation } from "react-router-dom";
import tw, { styled, css } from 'twin.macro';
import { version } from '../utils/config'

interface NavbarProps {
  showSidebar?: Dispatch<SetStateAction<boolean>>;
}

const Version = styled.i(() => [
  tw`
    text-xs text-dark font-medium not-italic text-right
    relative inline-block w-full self-end pb-[11px]
  `
])

const Burger = styled.div(() => [
  tw`
    w-[20px] h-[2px] bg-purple-primary rounded-[10px] relative mt-[6px]

    before:content-[''] before:w-full before:h-full
    before:bg-purple-primary before:rounded-[10px]
    before:absolute before:top-[-6px] 
    
    after:content-[''] after:w-full after:h-full
    after:bg-purple-primary after:rounded-[10px]
    after:absolute after:bottom-[-6px]
  `
])

const Navbar: FC<NavbarProps> = ({ showSidebar }) => {
  const location = useLocation();

  return (
    <div className="flex items-center justify-start gap-2 md:hidden mb-[5px]">
      <>
        {showSidebar && (
          <div
            onClick={() => showSidebar((prev) => !prev)}
            className="flex cursor-pointer md:hidden box-border h-[20px] pt-[3px] pr-[3px]"
          >
            {/*<Icon
              name={IconNames.MENU}
              className="fill-purple-500"
              family={IconFamily.SKETCH}
            />*/}
            <Burger/>
          </div>
        )}
        <div className="flex items-center justify-between w-full pl-[15px]">
          {location.pathname.includes("auth") ? (
            <Logo className="max-h-[40px] max-w-[200px] md:hidden" />
          ) : (
            <MobileLogo className="max-h-[40px] max-w-[160px] flex mt-[9px] md:hidden" />
          )}
          <Version>{version}</Version>
        </div>
      </>
    </div>
  );
};

export default Navbar;
