import { FC } from "react";
import Line from './Line'

const Footer: FC<{}> = () => {
  const year = (new Date()).getFullYear()
  return (
    <div className={`w-full relative pt-md pb-lg`}>
      <Line className={`bottom-[auto] top-[0]`}/>
      <span className={`block text-end`}>&#169; ООО «Симулятор будущего ТРОПАСС», {year}</span>
    </div>
  )
}

export default Footer;