import { useNavigate } from "react-router-dom";
import { ReactComponent as ErrorImage } from "../../assets/ui/hands_unplug.svg";
import H1 from "../../components/ui/typography/H1";
import P1 from "../../components/ui/typography/P1";
import Button from "../../components/ui/buttons/Button";
import H3 from "../../components/ui/typography/H3";

const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <div
      className="
      flex
      flex-wrap
      items-center
      justify-center
      w-[100vw]
      h-[100vh]
      p-2
    "
    >
      <ErrorImage
        className="
          w-full
          max-w-[500px]
          h-full
          max-h-[400px]
        "
      />
      <div
        className="
          flex
          flex-col
          gap-6
          items-center
          justify-center
          max-w-[400px]
          text-center
          text-dark-300
        "
      >
        <H1
          className="          
            text-dark-200
          "
        >
          404
        </H1>
        <H3>Страница не найдена</H3>
        <P1>Такой страницы не существуюет, проверьте корректность ссылки</P1>
        <Button onClick={() => navigate("home/dashboard")}>На главную</Button>
      </div>
    </div>
  );
};

export default ErrorPage;
