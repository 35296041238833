import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import { useDispatch } from "react-redux";
import profileResultReducer from "./slices/profileResultSlice";
import applicationsReducer from "./slices/applicationsSlice";
import personsReducer from "./slices/personsSlice";
import mlmodelReducer from "./slices/mlmodelSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    profileResults: profileResultReducer,
    applications: applicationsReducer,
    persons: personsReducer,
    mlmodels: mlmodelReducer,
  },
});

export type ReduxDispatch = typeof store.dispatch;
export const useReduxDispatch: () => ReduxDispatch = useDispatch;
export type ReduxState = ReturnType<typeof store.getState>;
