import { LoadingStatus } from "./utilityTypes";
import { DefaultResponseError } from "../api/interfaces";

export const getActionType = (type: string): LoadingStatus => {
  const returnType = type.split("/")[type.split("/").length - 1];
  switch (returnType) {
    case "pending":
      return "pending";
    case "fulfilled":
      return "fulfilled";
    case "rejected":
      return "rejected";
    default:
      return "idle";
  }
};

export const getMessageFromDefaultResponseError = (
  error: DefaultResponseError,
): string => {
  const firstError = Object.values(error)[0];
  return typeof firstError === "string" ? firstError : firstError[0];
};
