import React, { FC, memo } from "react";
import Icon, { IconNames, IconSize } from "../ui/icons/Icon";
import Text, { TextSizes } from "../ui/typography/Text";

export interface SidebarDropdownOptionProps {
  id: string;
  label: string;
  icon?: IconNames;
  pic?: string;
  onClick: () => void;
  active?: boolean;
}

const SidebarDropdownOption: FC<SidebarDropdownOptionProps> = ({
  id,
  label,
  icon,
  onClick,
  active,
  pic,
}) => {
  return (
    <div
      key={id}
      className={`
        flex
        items-center
        gap-[11px]
        cursor-pointer
        transition-colors
        ${active ? "bg-purple-300" : "bg-transparent"}
        rounded-full
        py-2
        px-4
      `}
      onClick={onClick}
    >
      {pic && (
        <img
          src={`data:image/svg+xml;base64,${pic}`}
          className="
              invert
            "
        />
      )}
      {icon && (
        <Icon
          name={icon}
          className={`
              ${IconSize.MEDIUM}
              fill-white
            `}
        />
      )}
      <Text size={TextSizes.SMALL}>{label}</Text>
    </div>
  );
};

export default memo(SidebarDropdownOption);
