import { FC, PropsWithChildren } from "react";
import clsx from "clsx";

const H2: FC<PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => {
  return (
    <h2 className={clsx("font-semibold text-xl md:text-[46px] xl:text-6xl leading-[1]", className)}>{children}</h2>
  );
};

export default H2;
