import { useEffect } from "react"
import { Outlet } from "react-router-dom"
import { useSelector } from "react-redux"

import { ReduxState, useReduxDispatch } from "../../redux/store"
import { getPersons } from "../../redux/slices/personsSlice"
import { getMlmodels } from "../../redux/slices/mlmodelSlice"

const HomeLayout = () => {
  // load mlmodels and persons
  const dispatch = useReduxDispatch();
  const getData = async () => {
    try {
      const data = await Promise.all([
        dispatch(getPersons()),
        dispatch(getMlmodels()),
      ])
      
      return data
    } catch (err) {
      console.log("error load", err)
    }
  }
  const { persons, render: personRender } = useSelector(
    (state: ReduxState) => state.persons,
  )
  const { filterValue, render: resultsRender } = useSelector(
    (state: ReduxState) => state.profileResults,
  )

  useEffect(() => {
    getData().then((data: any) => {})
  }, [personRender, resultsRender])

  return <Outlet />
};

export default HomeLayout;
