import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getApi from "../../api";
import { AxiosError } from "axios";
import { IAuthAPI, ILogin, IRegistration, IUser } from "../../api/interfaces";
import { LoadingStatus } from "../utilityTypes";
import { getMessageFromDefaultResponseError } from "../helpers";
import Cookies from "js-cookie";

interface IAuthSlice {
  status: LoadingStatus;
  error: string;
  user?: IUser;
}

export const getMe = createAsyncThunk(
  "auth/getMe",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getApi.getMe();
      return response.data;
    } catch (e: any) {
      const err = e as AxiosError<IAuthAPI.getMe.Error>;
      return rejectWithValue(err.response?.data);
    }
  },
);

export const login = createAsyncThunk(
  "auth/login",
  async (data: ILogin.Request, { rejectWithValue }) => {
    try {
      const response = await getApi.login(data);
      return response.data;
    } catch (e: any) {
      const err = e as AxiosError<ILogin.Error>;
      return rejectWithValue(err.response?.data);
    }
  },
);

export const register = createAsyncThunk(
  "auth/register",
  async (data: IRegistration.Request, { rejectWithValue }) => {
    try {
      const response = await getApi.register(data);
      return response.data;
    } catch (e) {
      const err = e as AxiosError<IRegistration.Error>;
      return rejectWithValue(err.response?.data);
    }
  },
);

const initialState: IAuthSlice = {
  status: "idle",
  error: "",
  user: undefined,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state) {
      state = initialState;
      Cookies.remove("access");
      Cookies.remove("refresh");
    },
  },
  extraReducers: (build) => {
    build
      .addCase(login.fulfilled, (state, action) => {
        state.status = "fulfilled";
        const access = action.payload?.access;
        const refresh = action.payload?.refresh;
        Cookies.set("access", access);
        Cookies.set("refresh", refresh);
      })
      .addCase(login.pending, (state) => {
        state.status = "pending";
        state.error = "";
      })
      .addCase(login.rejected, (state, action) => {
        const error = action.payload as ILogin.Error;
        state.status = "rejected";
        state.error = getMessageFromDefaultResponseError(error);
      })
      .addCase(register.fulfilled, (state) => {
        state.status = "fulfilled";
      })
      .addCase(register.pending, (state) => {
        state.status = "pending";
        state.error = "";
      })
      .addCase(register.rejected, (state, action) => {
        const error = action.payload as IRegistration.Error;
        state.status = "rejected";
        state.error = getMessageFromDefaultResponseError(error);
      })
      .addCase(getMe.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.user = action.payload;
      })
      .addCase(getMe.pending, (state) => {
        state.status = "pending";
        state.error = "";
      })
      .addCase(getMe.rejected, (state, action) => {
        const error = action.payload as IAuthAPI.getMe.Error;
        state.error = getMessageFromDefaultResponseError(error);
        state.status = "rejected";
      });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
