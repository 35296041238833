import tw, { styled } from 'twin.macro';

export const Line = styled.div(() => [
  tw`
    w-[calc(100%+60px)] h-[12px] bg-purple rounded-full
    absolute left-[15px] bottom-[-15px]
    hidden
    sm:block
    xl:(bottom-[-30px] left-[30px])
    2xl:(w-[calc(100%-30px)])
  `,
])

export default Line