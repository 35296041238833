import { FC } from "react";
// TODO rename and delete style in all icons in assets/icons folder
import FutureIcon from "./FutureIcon";
import SketchIcon from "./SketchIcon";

export enum IconNames {
  USERS = "users",
  DROPDOWN = "dropdown",
  DOWNLOAD = "download",
  EXPAND = "expand",
  SUN = "sun",
  MENU = "menu",
  COLLAPSE = "collapse",
  EXIT = "exit",
  CHART_PIE = "chart_pie",
  COMPASS = "compass",
  FILE_COPY = "file_copy",
  SETTINGS = "settings",
  FILE_EDIT = "file_edit",
  FILE_DELETE = "file_Delete",
  REFRESH = "refresh",
  RESULTS = "results",
}

export enum IconSize {
  SMALL = "h-[16px]",
  MEDIUM = "h-[24px]",
  LARGE = "h-[36px]",
  XL = "h-[36px] md:h-[48px]"
}

export enum IconFamily {
  SKETCH = "sketch",
  FUTURE = "future",
}
export interface IconProps {
  name: IconNames;
  className?: string;
  style?: {[key: string]: string | number}
}

interface IconBaseProps extends IconProps {
  family?: IconFamily;
}

const Icon: FC<IconBaseProps> = ({
  name,
  className,
  style,
  family = IconFamily.FUTURE,
}) => {
  switch (family) {
    case IconFamily.SKETCH:
      return <SketchIcon name={name} className={className} style={style} />;
    case IconFamily.FUTURE:
      return <FutureIcon name={name} className={className} style={style} />;
    default:
      return <FutureIcon name={name} className={className} style={style} />;
  }
};

export default Icon;
