import { FC, PropsWithChildren } from "react";
import clsx from "clsx";

const H3: FC<PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => {
  return (
    <h3 className={clsx("font-semibold text-4xl", className)}>{children}</h3>
  );
};

export default H3;
