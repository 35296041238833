import { FC, PropsWithChildren, useState } from "react";
import { useLocation } from "react-router-dom";
import { Navigate, To } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const AuthGuard: FC<PropsWithChildren> = ({ children }) => {
  const { logged } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<To>("");

  if (!logged) {
    if (pathname !== requestedLocation) setRequestedLocation(pathname);
    return <Navigate to="/auth/login" />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation("");
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

export default AuthGuard;
