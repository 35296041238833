import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getApi from "../../api";
import { AxiosError } from "axios";
import { IMlmodel } from "../../api/interfaces";
import { LoadingStatus } from "../utilityTypes";
import SERVICE_COLOR from "../../utils/service-color";

interface IMlmodelSlice {
  status: LoadingStatus;
  error: string;
  mlmodels: Array<IMlmodel.Response>;
}

export const getMlmodels = createAsyncThunk(
  "mlmodels/getMlmodels",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getApi.getMlmodels();
      return response.data;
    } catch (e: any) {
      const err = e as AxiosError<IMlmodel.Error>;
      return rejectWithValue(err.response?.data);
    }
  },
);

const initialState: IMlmodelSlice = {
  status: "idle",
  error: "",
  mlmodels: [],
};

const mlmodelSlice = createSlice({
  name: "mlmodels",
  initialState,
  reducers: {},
  extraReducers: (build) => {
    build
      .addCase(getMlmodels.fulfilled, (state, action) => {
        state.status = "fulfilled";
        action.payload.forEach((m, index) => {
          m.color = SERVICE_COLOR[index] || m.color
        })
        state.mlmodels = action.payload;
      })
      .addCase(getMlmodels.pending, (state) => {
        state.status = "pending";
        state.error = "";
      });
  },
});

export default mlmodelSlice.reducer;
