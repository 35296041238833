import React, { FC, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/Navbar";
import { useLocation } from 'react-router-dom'
import Footer from '../../components/ui/Footer'

const ForecastLayout: FC = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  return (
    <div className="flex h-screen w-[100vw]">
      <Sidebar show={showSidebar} setShow={setShowSidebar} />
      <div className="w-full overflow-y-scroll">
        <div className={`
          px-sm max-w-[1600px] mx-auto md:px-md lg:px-lg
          min-h-screen
          flex flex-col justify-between items-start gap-md
          overflow-x-hidden
        `}>
          <div className="w-full">
            <Navbar showSidebar={setShowSidebar} />
            <Outlet />
          </div>
          <Footer/>
        </div>
      </div>
    </div>
  );
};

export default ForecastLayout;
