import React, { FC, PropsWithChildren } from "react";

export enum TextSizes {
  REGULAR = "24px",
  SMALL = "16px",
}

interface TextProps {
  size?: TextSizes;
  color?: string;
}

const Text: FC<PropsWithChildren<TextProps>> = ({
  size = TextSizes.REGULAR,
  color = "dark-primary",
  children,
}) => {
  return (
    <div
      className={`
        text-[${size}]
        text-${color}
        leading-none
      `}
    >
      {children}
    </div>
  );
};

export default Text;
