import { FC, PropsWithChildren } from "react";
import clsx from "clsx";

const P1: FC<PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => {
  return <p className={clsx("text-2xl leading-none", className)}>{children}</p>;
};

export default P1;
