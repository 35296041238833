import { FC, PropsWithChildren } from "react";
import clsx from "clsx";

const H1: FC<PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => {
  return (
    <h1 className={clsx("font-semibold text-5xl md:text-8xl", className)}>
      {children}
    </h1>
  );
};

export default H1;
