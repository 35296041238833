import { LoadableReduxData } from "../utilityTypes";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getApi from "../../api";
import Axios, { AxiosError } from "axios";
import { getMessageFromDefaultResponseError } from "../helpers";
import Cookies from "js-cookie";
import {
  IApplication,
  IApplicationAPI,
} from "../../api/interfaces/applications";

interface IControl extends LoadableReduxData {
  field: IApplication.Form.Field;
  data: IApplication.Form.DataItem;
}

interface IControls {
  [fieldId: string]: IControl;
}

interface IForm extends LoadableReduxData {
  controls: IControls;
}

interface IDropdownResults {
  [key: string]: IApplication.Dropdown.Values;
}

interface IDropdown extends LoadableReduxData {
  results: IDropdownResults;
}

interface IApplicationsSlice {
  form: IForm;
  dropdown: IDropdown;
}

export const saveApplicationFieldData = createAsyncThunk(
  "applications/saveApplicationFieldData",
  async (data: IApplication.Form.DataItem, { rejectWithValue }) => {
    try {
      const id = data.id;
      if (id) {
        const response = await getApi.patchApplicationFieldData(id, {
          input_data_id: data.input_data_id,
          profile_answer: data.profile_answer,
          person_id: data.person_id,
        });
        return response.data;
      } else {
        const response = await getApi.postApplicationFieldData({
          input_data_id: data.input_data_id,
          profile_answer: data.profile_answer,
          person_id: data.person_id,
        });
        return response.data;
      }
    } catch (e) {
      const err = e as AxiosError<IApplicationAPI.saveFieldData.Error>;
      return rejectWithValue({
        id: data.input_data_id,
        data: err.response?.data,
      });
    }
  },
);

export const postApplicationFieldData = createAsyncThunk(
  "applications/postApplicationFieldData",
  async (
    data: IApplicationAPI.saveFieldData.Request[],
    { rejectWithValue },
  ) => {
    try {
      const response = await getApi.postApplicationFieldData(data);
      return response.data;
    } catch (e) {
      const err = e as AxiosError<IApplicationAPI.saveFieldData.Error>;
      return rejectWithValue(err);
    }
  },
);

export const getApplicationFieldValues = createAsyncThunk(
  "applications/getApplicationFieldValues",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await getApi.getApplicationValues(id);
      return response.data;
    } catch (e) {
      const err = e as AxiosError<IApplicationAPI.getFieldValues.Error>;
      return rejectWithValue(err);
    }
  },
);

export const getApplicationFields = createAsyncThunk(
  "applications/applicationFields",
  async (
    { person_id, person_type }: { person_id: string; person_type: number },
    { rejectWithValue },
  ) => {
    try {
      const responseFields = await getApi.getApplicationFields(person_type);
      const responseData = await getApi.getApplicationValues(person_id);

      const form = responseData.data.reduce(
        (acc, curr) => {
          acc[curr.input_data_id] = {
            ...curr,
            person_id,
          };
          return acc;
        },
        {} as { [key: string]: IApplication.Form.DataItem },
      );

      const fields = responseFields.data;
      const controls: IControls = fields.reduce((acc, curr) => {
        acc[curr.id] = {
          field: curr,
          status: "idle",
          error: "",
          data: form[curr.id] || {
            input_data_id: curr.id,
            profile_answer: "",
            person_id,
          },
        };
        return acc;
      }, {} as IControls);

      return {
        controls,
      };
    } catch (e: any) {
      const err = e as AxiosError<IApplicationAPI.getFields.Error>;
      return rejectWithValue(err.response?.data);
    }
  },
);

export const getApplicationDropdownValues = createAsyncThunk(
  "applications/applicationDropdownValues",
  async (
    {
      id,
      url,
    }: { id: string; url: string; params?: { [key: string]: string } },
    { rejectWithValue },
  ) => {
    try {
      const response =
        await Axios.get<IApplicationAPI.getDropdownValues.Response>(url, {
          headers: {
            Authorization: `Bearer ${Cookies.get("access")}`,
            "Content-Type": "application/json",
          },
        });
      return { id, data: response.data };
    } catch (e: any) {
      const err = e as AxiosError<IApplicationAPI.getDropdownValues.Error>;
      return rejectWithValue({ id, data: err.response?.data });
    }
  },
);

const initialState: IApplicationsSlice = {
  dropdown: {
    results: {},
    status: "idle",
    error: "",
  },
  form: {
    status: "idle",
    error: "",
    controls: {},
  },
};

const applicationsSlice = createSlice({
  name: "applications",
  initialState,
  reducers: {},
  extraReducers: (build) => {
    build
      .addCase(getApplicationFields.fulfilled, (state, action) => {
        state.form.status = "fulfilled";
        state.form.controls = action.payload.controls || {};
      })
      .addCase(getApplicationFields.pending, (state) => {
        state.form.status = "pending";
        state.form.error = "";
      })
      .addCase(getApplicationFields.rejected, (state, action) => {
        const error = action.payload as IApplicationAPI.getFields.Error;
        state.form.error = getMessageFromDefaultResponseError(error);
        state.form.status = "rejected";
        state.form.controls = {};
      })
      .addCase(getApplicationDropdownValues.fulfilled, (state, action) => {
        state.dropdown.status = "fulfilled";
        if (!state.dropdown.results[action.payload.id]) {
          state.dropdown.results[action.payload.id] = {
            ...action.payload.data,
            results: action.payload.data.results.map((item) => item.optvalue),
          };
        } else if (state.dropdown.results[action.payload.id].next !== action.payload.data.next) {
          state.dropdown.results[action.payload.id].next =
            action.payload.data.next;
          state.dropdown.results[action.payload.id].count =
            action.payload.data.count;
          state.dropdown.results[action.payload.id].previous =
            action.payload.data.previous;
          state.dropdown.results[action.payload.id].results = [
            ...state.dropdown.results[action.payload.id].results,
            ...action.payload.data.results.map((item) => item.optvalue),
          ];
        }
      })
      .addCase(getApplicationDropdownValues.pending, (state) => {
        state.dropdown.status = "pending";
        state.dropdown.error = "";
      })
      .addCase(getApplicationDropdownValues.rejected, (state, action) => {
        const error = action.payload as {
          id: string;
          data: IApplicationAPI.getDropdownValues.Error;
        };
        state.dropdown.error = getMessageFromDefaultResponseError(error.data);
        delete state.dropdown.results[error.id];
        state.dropdown.status = "rejected";
      })
      .addCase(saveApplicationFieldData.fulfilled, (state, action) => {
        state.form.controls[action.meta.arg.input_data_id].data =
          action.payload;
        state.form.controls[action.meta.arg.input_data_id].status = "fulfilled";
      })
      .addCase(saveApplicationFieldData.pending, (state, action) => {
        state.form.controls[action.meta.arg.input_data_id].status = "pending";
        state.form.controls[action.meta.arg.input_data_id].error = "";
      })
      .addCase(saveApplicationFieldData.rejected, (state, action) => {
        const error = action.payload as {
          id: string;
          data: IApplicationAPI.saveFieldData.Error;
        };
        state.form.controls[action.meta.arg.input_data_id].error =
          getMessageFromDefaultResponseError(error.data);
        state.form.controls[action.meta.arg.input_data_id].status = "rejected";
      });
  },
});

export default applicationsSlice.reducer;
