import { useEffect, useState } from "react";
import getApi from "../../api";
import parse from "html-react-parser";
import { IBlogPost } from "../../api/interfaces";
import H2 from "../../components/ui/typography/H2";
import { useParams } from "react-router-dom";
import WidePage from '../../components/WidePage';
import { ReactComponent as FormImg } from "../../assets/hands/design/d.svg";
import { theme } from 'twin.macro';

const BlogPage = () => {
  const [content, setContent] = useState<IBlogPost.Response>({
    id: "",
    body: "",
    title: "",
  });

  const { id } = useParams();

  const getPolicy = async () => {
    id && getApi.getBlogPost(id).then((data) => setContent(data.data));
  };

  useEffect(() => {
    getPolicy();
  }, [id]);

  return (
    <WidePage
      img={<FormImg className="w-full h-full"/>}
      imgBgColor={theme`colors.blue.primary`}
      shadowColor={theme`colors.dark.200`}
      header={(
        <div>
          <H2>{content.title}</H2>
        </div>
      )}
    >
      <div
        className="pt-sm sm:pt-md xl:pt-lg text-dark-300 text-base font-medium md:text-[18px]"
        style={{fontFamily: "Inter"}}
      >{parse(content.body)}</div>
    </WidePage>
  );
};

export default BlogPage;
