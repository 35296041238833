import { FC, useState } from "react";
import { Outlet } from "react-router-dom";
import tw, { styled, css, theme } from "twin.macro";

import Navbar from "../../components/Navbar";
import Modal from "../../components/Modal";
import Button, { ButtonStyles } from "../../components/ui/buttons/Button";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import P1 from "../../components/ui/typography/P1";
import { ReactComponent as Logo } from "../../assets/ui/logo.svg";
import { version } from '../../utils/config'

const Version = styled.i(() => [
  tw`
    text-xs text-light font-medium not-italic text-center
    relative inline-block w-full top-[-12px]
  `
])

const AuthLayout: FC = () => {
  const [acceptCookies, setAcceptCookies] = useState<boolean>(
    !!Cookies.get("acceptCookie"),
  );

  return (
    <div
      style={{
        backgroundImage: "url('/assets/backgrounds/background_primary_t.svg')",
        backgroundPositionX: "center",
        backgroundPositionY: "-35%",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
      }}
    >
      <Modal isOpen={!acceptCookies} header="">
        <div className="flex flex-col justify-end items-end mb-7 gap-4 max-w-[500px]">
          <div className="mb-4 whitespace-normal px-2">
            <P1>
              На этом сайте используются cookies,{" "}
              <Link to={"/blog/3"} className="underline text-purple">
                подробнее
              </Link>
            </P1>
          </div>

          <Button
            color={ButtonStyles.PURPLE_SECONDARY}
            onClick={() => {
              Cookies.set("acceptCookie", "true");
              setAcceptCookies(true);
            }}
            size="small"
          >
            Понятно
          </Button>
        </div>
      </Modal>
      <div className="w-[100vw] min-h-[100vh] h-[100%] justify-between flex flex-col bg-gradient-to-b from-purple-600 to-purple-500">
        <div className="p-6 flex justify-center items-center">
          <div>
            <Logo className="max-h-[40px] max-w-[200px]"/>
            <Version>{version}</Version>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center self-center h-[100%] gap-3 w-[90%] z-10">
          <Outlet />
        </div>
        <Info>
          <p>
            ООО "Симулятор Будущего Тропасс" - российская компания, которая разработала систему ТРОПАСС. Это цифровая платформа для прогнозирования, на основе обработки больших массивов данных и искусственного интеллекта. При разработке использовались языки программирования и фреймворки: Python, Django, PostgreSQL, R, HTML, JavaScript, React.
          </p>
          <Link className="underline" to="/fc">
            Функциональные характеристики
          </Link>
          <p>Контактная информация: ИНН 9704209245</p>
          <p>Юридический адрес: 121099, город Москва, Панфиловский пер, д. 4 стр. 1, помещ. 53</p>
          <p>Контакты: +7 916 304-59-66 tropass.future@gmail.com</p>

        </Info>
      </div>
    </div>
  );
};

const Info = styled('div')(() => [
  tw`max-w-[1280px] text-white mx-auto p-[15px] text-[14px] leading-tight sm:text-[20px] pt-[30px]`,
])

export default AuthLayout;
