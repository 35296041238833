import React, { FC } from "react";
import Button, { ButtonProps } from "./Button";

const SidebarButton: FC<ButtonProps> = (props) => {
  return (
    <Button
      {...props}
      className="
        rounded-e-none
        text-[16px]
        self-end
        max-w-[277px]
        mt-[16px]
      "
      size="smallest"
      style={{
        maxWidth: '277px',
        borderStartEndRadius: '0px',
        borderEndEndRadius: '0px',
      }}
    >
      {props.children}
    </Button>
  );
};

export default SidebarButton;
