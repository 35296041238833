import { Outlet } from "react-router-dom"
import Footer from '../components/ui/Footer'

const WideLayout = () => {
  return (
    <div className={`
      max-w-[1600px] min-h-screen overflow-x-hidden overflow-y-auto
      mx-auto px-sm md:px-md lg:px-lg
      flex flex-col justify-between items-start
    `}>
      <div className={`w-full`}>
        <Outlet />
      </div>
      <Footer/>
    </div>
  )
};

export default WideLayout;
