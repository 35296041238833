export const SERVICE_COLOR = [
  [155, 103, 212],
  [71, 61, 108],
  [207, 135, 207],
  [117, 116, 207],
  [188, 188, 244],
  [163, 156, 189],
  [151, 151, 239],
  [17, 7, 56],
  [68, 0, 154],
]

export default SERVICE_COLOR