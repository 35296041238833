import { LoadableReduxData } from "../utilityTypes";
import { IPerson, IPersonAPI } from "../../api/interfaces/persons";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import getApi from "../../api";
import { getMessageFromDefaultResponseError } from "../helpers";

interface IPersonsSlice extends LoadableReduxData {
  persons: Array<IPerson>;
  render: number;
  colors: {[id: string]: number[]}
}

export const createPerson = createAsyncThunk(
  "persons/createPerson",
  async (data: IPersonAPI.createPerson.Request, { rejectWithValue }) => {
    try {
      const response = await getApi.createPerson(data);
      return response.data;
    } catch (e) {
      const err = e as AxiosError<IPersonAPI.createPerson.Error>;
      return rejectWithValue(err.response?.data);
    }
  },
);

export const editPerson = createAsyncThunk(
  "persons/editPerson",
  async (data: IPersonAPI.editPerson.Request, { rejectWithValue }) => {
    try {
      const response = await getApi.editPerson(data);
      return response.data as IPerson;
    } catch (e) {
      const err = e as AxiosError<IPersonAPI.createPerson.Error>;
      return rejectWithValue(err.response?.data);
    }
  },
);

export const deletePerson = createAsyncThunk(
  "persons/deletePerson",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await getApi.deletePerson(id);
      return response.data;
    } catch (e) {
      const err = e as AxiosError<IPersonAPI.deletePerson.Error>;
      return rejectWithValue(err.response?.data);
    }
  },
);

export const getPersons = createAsyncThunk(
  "persons/getPersons",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getApi.getPersons();
      return response.data;
    } catch (e) {
      const err = e as AxiosError<IPersonAPI.getPersons.Error>;
      return rejectWithValue(err.response?.data);
    }
  },
);

const initialState: IPersonsSlice = {
  status: "idle",
  error: "",
  persons: [],
  render: 0,
  colors: {}, //Запоминаем цвета троп присланные в первый раз
};

const personsSlice = createSlice({
  name: "persons",
  initialState,
  reducers: {},
  extraReducers: (build) => {
    build
      .addCase(getPersons.fulfilled, (state, action) => {
        // Запоминаем цвета присланные в первый раз, и присваиваем их во последующие разы
        action.payload.forEach((person) => {
          if (state.colors[person.id]) {
            person.color = state.colors[person.id];
          } else {
            state.colors[person.id] = person.color;
          }
        })

        state.persons = action.payload;
        state.status = "fulfilled";
        state.error = "";
      })
      .addCase(getPersons.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getPersons.rejected, (state, action) => {
        const error = action.payload as IPersonAPI.getPersons.Error;
        state.status = "rejected";
        state.persons = [];
        state.error = getMessageFromDefaultResponseError(error);
      })
      .addCase(createPerson.fulfilled, (state, action) => {
        state.render = state.render + 1;
        state.status = "fulfilled";
        state.error = "";
      })
      .addCase(createPerson.pending, (state) => {
        state.status = "pending";
      })
      .addCase(createPerson.rejected, (state, action) => {
        const error = action.payload as IPersonAPI.createPerson.Error;
        state.status = "rejected";
        state.error = getMessageFromDefaultResponseError(error);
      })
      .addCase(editPerson.fulfilled, (state, action) => {
        const editedPerson = state.persons.findIndex(
          (person, index) => person.id === action.payload.id,
        );
        state.persons[editedPerson] = action.payload;
        state.status = "fulfilled";
        state.error = "";
      })
      .addCase(editPerson.pending, (state) => {
        state.status = "pending";
      })
      .addCase(editPerson.rejected, (state, action) => {
        const error = action.payload as IPersonAPI.createPerson.Error;
        state.status = "rejected";
        state.error = getMessageFromDefaultResponseError(error);
      })
      .addCase(deletePerson.fulfilled, (state, action) => {
        state.render = state.render + 1;
        state.status = "fulfilled";
        state.error = "";
      })
      .addCase(deletePerson.pending, (state) => {
        state.status = "pending";
      })
      .addCase(deletePerson.rejected, (state, action) => {
        const error = action.payload as IPersonAPI.createPerson.Error;
        state.status = "rejected";
        state.error = getMessageFromDefaultResponseError(error);
      });
  },
});

export default personsSlice.reducer;
