import React, { FC, lazy, Suspense } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import AuthLayout from "../layouts/AuthLayout";
import CloudSettingsIndicator from "../components/ui/indicators/CloudSettingsIndicator";
import ErrorPage from "../pages/general/ErrorPage";
import ForecastLayout from "../layouts/ForecastLayout/ForecastLayout";
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
import HomeLayout from "../layouts/HomeLayout";
import WideLayout from "../layouts/WideLayout";
import BlogPage from "../pages/general/BlogPage";
import FunctionalCharacteristics from "../pages/general/FunctionalCharacteristics"

const Loadable = (Component: React.LazyExoticComponent<FC>) => () => {
  return (
    //TODO add loading screen
    <Suspense fallback={<div className="flex justify-center items-center min-h-screen"><CloudSettingsIndicator/></div>}>
      <Component />
    </Suspense>
  );
};

const Router = () => {
  return useRoutes([
    {
      path: "/",
      element: <Navigate to="/home/dashboard" />,
    },
    {
      path: "auth",
      element: (
        <GuestGuard>
          <AuthLayout />
        </GuestGuard>
      ),
      errorElement: <ErrorPage />,
      children: [
        { path: "login", element: <Login /> },
        { path: "registration", element: <Registration /> },
      ],
    },
    {
      path: "home",
      element: (
        <AuthGuard>
          <HomeLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "dashboard",
          element: <ForecastLayout/>,
          children: [
            { index: true, element: <Forecast /> },
          ],
        },
        {
          path: '',
          element: <WideLayout/>,
          children: [
            {
              path: 'avatar-form',
              element: <AvatarForm />,
            },
            {
              path: "application-form",
              element: <ApplicationForm />,
            },
          ]
        },
        {
          index: true,
          element: <Navigate to="/home/dashboard" />,
        },
      ],
    },
    {
      path: "",
      element: <WideLayout/>,
      children: [
        {
          path: "/blog/:id",
          element: <BlogPage />,
        }, {
          path: "/fc",
          element: <FunctionalCharacteristics/>
        }
      ]
    },
    {
      path: "*",
      element: <ErrorPage />,
    },
  ]);
};

const Registration = Loadable(lazy(() => import("../pages/Registration")));
const Login = Loadable(lazy(() => import("../pages/Login")));
const Forecast = Loadable(lazy(() => import("../pages/Forecast")));
const ApplicationForm = Loadable(
  lazy(() => import("../pages/ApplicationForm")),
);
const AvatarForm = Loadable(lazy(() => import("../pages/AvatarForm")));

export default Router;
